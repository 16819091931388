@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@700&family=Noto+Sans+JP:wght@400;500;700&family=Roboto:ital,wght@0,500;0,700;0,900;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');

/******* PC、スマホ共通スタイル *******/
html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Noto Sans JP', sans-serif;
    color: #282828;
    margin: 0 auto;
    letter-spacing: 0.5px;
    width: 100%;
    overflow-x: hidden; /* 横スクロールを防止する */
}

h1 {
    /* font-family: 'Roboto', sans-serif; */
    font-weight: 900;
    /* white-space: nowrap; */
}

h2 {
    margin: 0;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    color: #fff;
}

p {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

.sec-title-en {
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    font-weight: 400;
    font-style: normal;
    /* color: #FFC639; */
    color: #fdfdfd;
}

/******* ナビゲーションメニューの問い合わせボタン *******/
.nav-btn {
    text-decoration: none;
    display: inline-flex;
    width: 140px;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 14px;
    color: #fff;
    border-radius: 100vh;
    background-image: linear-gradient(90deg, #67DEFF 0%, #006142 100%);
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    padding: 10px;
}
.nav-btn span {
    color: #fff;
}
.nav-btn img {
    height: 16px;
    margin-right: 7px;
    margin-bottom: -4px;
}
.nav-btn:hover {
    background-image: initial;
    background-color: #3148B7;
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .3);
    box-shadow: 0 5px 15px rgba(0, 0, 0, .3);
}

/* ヘッダー */
header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    width: 100%;
    box-shadow: 4px 4px 15px 2px #ccc;
    overflow-x: hidden; /* 横スクロールを防止する */
}

header {
    display: none;
}

/* ロゴのレイアウト */
#logo {
    margin-left: 50px;
}
#logo img {
    height: 30px;
}
/* ナビゲーションのレイアウト */
#nav-pc {
    width: 100%;
    text-align: right;
    font-size: 15px;
    padding-top: 15px;
    padding-bottom: 10px;
    margin-right: 50px;
}
/* ナビゲーションのリンクの装飾設定 */
#nav-pc > a {
    text-decoration: none;
    margin-left: 10px;
    color: #282828;
}

/******* TOPへ戻るボタン *******/
#pagetop {
    height: 50px;
    width: 50px;
    position: fixed;
    right: 10px;
    bottom: 85px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 150;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .3);
    visibility: hidden;
    transition: bottom 0.3s ease;
}
#pagetop.show {
    visibility: visible;
}
.pagetop-btn {
    height: 10px;
    width: 10px;
    border-top: 3px solid #ccc;
    border-right: 3px solid #ccc;
    transform: translateY(20%) rotate(-45deg);
}

/******* ハンバーガーメニューの設定 *******/
/* SPメニュー用のチェックボックスを非表示にする */
.drawer-hidden {
    display: none;
}
@media screen and (min-width: 1160px) {
    /* SPのナビゲーションは非表示 */
    #nav-sp {
        display: none;
    }
}
@media screen and (max-width: 1160px) {
    #nav-pc {
        display: none;
    }

    /* SP用ナビゲーションの表示切替*/
    #nav-sp {
        background: #fff;
        position: absolute;
        left: 0;
        top: -150vh;
        width: 100%;
        height: 100vh;
        z-index: 300;
        transition: .5s;
        text-align: center;
        padding-top: 20px;
        overflow-x: hidden; /* 横スクロールを防止する */
    }
    #fv-message {
      margin-top: 0 !important
    }

    /* liの点を消す */
    .nav-list {
        list-style: none;
        padding: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    /* リンクの下線を消す */
    .nav-item a {
        text-decoration: none;
        color: #282828;
        font-size: 20px;
    }
    .nav-item {
        margin: 20px auto;
    }
    .nav-btn {
        width: 240px;
        margin-top: 50px;
    }
    /* ハンバーガーアイコンの設置スペース */
    .drawer-open {
        display: flex;
        height: 60px;
        width: 60px;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 310;
        cursor: pointer;
    }
    
    /* ハンバーガーメニューのアイコン */
    .drawer-open span,
    .drawer-open span:before,
    .drawer-open span:after {
        content: '';
        display: block;
        height: 3px;
        width: 25px;
        border-radius: 3px;
        background: #333;
        transition: 0.5s;
        position: absolute;
    }
    
    /* 三本線の一番上の棒の位置調整 */
    .drawer-open span:before {
        bottom: 8px;
    }
    
    /* 三本線の一番下の棒の位置調整 */
    .drawer-open span:after {
        top: 8px;
    }
    
    /* アイコンがクリックされたら真ん中の線を透明にする */
    #drawer-input:checked ~ .drawer-open span {
        background: rgba(255, 255, 255, 0);
    }
    
    /* アイコンがクリックされたらアイコンが×印になように上下の線を回転 */
    #drawer-input:checked ~ .drawer-open span::before {
        bottom: 0;
        transform: rotate(45deg);
    }
    
    #drawer-input:checked ~ .drawer-open span::after {
        top: 0;
        transform: rotate(-45deg);
    }
    
    /* アイコンがクリックされたらメニューを表示 */
    #drawer-input:checked ~ #nav-sp {
        top: 0;/* メニューを画面に入れる */
    }
}
/******* 画面下部固定表示メニューのスタイル *******/
#fixed-tab {
    position: fixed;
    bottom: 0;
    height: 80px;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    overflow-x: hidden; /* 横スクロールを防止する */
}
#fixed-tab > div {
    height: 50px;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    width: 20%;
    text-align: center;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    color: #FF7F3E;
    position: relative;
}
#fixed-tab > div > div {
    display: flex;
    justify-content: center;
    align-items: center;
}
#fixed-tab > div > div > span {
    margin: 0 5px;
}
#fixed-tab a {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
#fixed-tab > div:nth-child(3) {
    background: #FF7F3E;
    color: #fff;
}
#fixed-tab img {
    height: 40px;
    margin-right: 5px;
}

/******* ファーストビュー *******/
#first-view {
    position: relative;
    min-height: 90vh;
    background: url("./assets/images/Seminarimages/fv-bg.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow-x: hidden; /* 横スクロールを防止する */
}
#fv-message {
    /* padding-top: 120px; */
    color: #006142;
    max-width: 620px;
    width: 60%;
    height: 100%;
    z-index: 11;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 100px;
}
#fv-message .logo {
    text-align: left;
}
#fv-message .logo img {
    width: 200px;
}
#fv-message > h1 {
    font-size: 62px;
    font-weight: bold;
    margin: 20px;
}
#fv-message .middle {
    font-size: 50px;
}
#fv-message .small {
    font-size: 40px;
}
#fv-message > p {
    font-size: 20px;
    font-weight: 700;
}
#fv-message .date {
    font-size: 46px;
}
#fv-message .place > span{
    font-size: 16px;
    background: #006142;
    color: #fff;
    padding: 2px 10px;
    margin-right: 10px;
    vertical-align: middle;
}

/* 問い合わせボタン */
.fv-btn {
    text-decoration: none;
    display: inline-flex;
    width: 340px;
    height: 72px;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 26px;
    font-weight: 700;
    color: #fff;
    background: #FF7F3E;
    border: 3px solid #FF7F3E;
    border-radius: 100vh;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    padding: 3px 14px 3px 14px;
    margin: 20px auto 0;
    transition: 0.5s; 
    position: relative;
}
.fv-btn img {
    height: 28px;
    margin-right: 17px;
    margin-bottom: -4px;
}
.fv-btn:hover {
    color: #FF7F3E;
    background: #fff;
    border: 3px solid #FF7F3E;
}
.fv-btn:before {
    content: "";
    width: 28px;
    height: 28px;
    background-image: url('./assets/images/Seminarimages/mail-white.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-right: 10px;
}

.fv-btn:hover::before {
    background-image: url('./assets/images/Seminarimages/mail-orange.png');
}

#fv-image {
    width: 100%;
    height: 80%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: center;
}

#fv-image > div{
    width: 15%;
    margin: 1%;
    display: flex;
    align-items: center;
}
.fv-image-inner {
    position: relative;
    width: 100%;
}
.fv-image-inner img {
    /* height: 100%; */
    width: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 50%;
    aspect-ratio: 1/1;
    box-shadow: 15px 22px 32px #ccc;
}
.fv-image-inner p {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    position: absolute;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
    width: 150%;
    z-index: 1;
    text-shadow:0 0 2px #fff,0 0 2px #fff,0 0 2px #fff,0 0 2px #fff,0 0 2px #fff,0 0 2px #fff,0 0 2px #fff,0 0 2px #fff,0 0 2px #fff,0 0 2px #fff,0 0 2px #fff,0 0 2px #fff,0 0 2px #fff,0 0 2px #fff,0 0 2px #fff,0 0 2px #fff;
}

/******* About どんなイベント？ *******/
#about {
    background-color: #006142;
    background:url("./assets/images/Seminarimages/about-bg.jpg") no-repeat center;
    background-size: cover;
    color: #fff;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 50px;
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden; /* 横スクロールを防止する */
}
#about-message p {
    font-size: 40px;
    font-weight: 700;
    margin-top: 40px;
}
#about h2 {
    font-size: 42px;
}
#about-elements {
    display: flex;
    justify-content: center;
}
#about-elements > div {
    width: 30%;
    background: -webkit-linear-gradient(0deg, #fff, #f6ef8c);
    border-radius: 50%;
    aspect-ratio: 1/1;
    margin: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
#about-elements > div > p {
    color: #006142;
    font-weight: 700;
    margin: 10px;
    font-size: 32px;
}

/*　Guest Doctor 左と右を囲う全体のエリア*/
#doctor h2 {
    text-shadow: #006142 1px 0 10px;
}

#fixed-moving {
    position: relative;/*position stickyの基点にするため relativeをかける*/
    display: flex;/*左エリア、右エリア横並び指定*/
    flex-wrap: wrap;/*ボックスの折り返し可*/
    width: 100%;
    overflow-x: hidden; /* 横スクロールを防止する */
}

/*左エリア*/
#fixed-area {
    /* position: -webkit-sticky; */
    position: sticky;
    top:0;
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background:url("./assets/images/Seminarimages/fixed-area-bg.jpg") no-repeat center;
    background-size:cover;
    color:#fff;
}

/*右エリア*/
#moving-area {
    width:50%;
}
.moving-area-inner {
    min-height: 100vh;
    margin: 0 auto;
    padding-top: 36px;
    background:url("./assets/images/Seminarimages/moving-area-bg-1.jpg") no-repeat top;
    background-size:cover;
}

.moving-area-inner:nth-of-type(2n){
    background:url("./assets/images/Seminarimages/moving-area-bg-2.jpg") no-repeat top;
    background-size: cover;
}

.moving-area-inner .area {
    width: 95%;
    margin: 0 auto;
}
.moving-area-inner dl {
    margin: 0;
    padding: 0;
}

.moving-area-inner dt p {
    font-size: 22px;
    font-weight: 700;
}
.moving-area-inner dt span {
    font-size: 22px;
}
.moving-area-inner dd > div:first-child {
    text-align: right;
    max-width: 600px;
}
.moving-area-inner dd > div:nth-child(3) {
    margin-top: 10px;
}
.moving-area-inner .doctor-name {
    font-size: 32px;
    font-weight: 700;
}
.moving-area-inner .doctor-name span {
    font-size: 22px;
}
.moving-area-inner .doctor-title {
    font-size: 22px;
    font-weight: 700;
}
.moving-area-inner dd img {
    width: 100%;
    max-width: 305px;
    object-fit: cover;
    object-position: top;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    margin-bottom: -82px;
    box-shadow: 15px 22px 32px #ccc;
}

.moving-area-inner .date {
    font-size: 40px;
}
.moving-area-inner .number {
    font-size: 20px;
    background: #006142;
    color: #fff;
    padding: 5px 10px;
    margin-right: 10px;
    vertical-align: text-top;
}
.moving-area-inner .course-title {
    text-indent: -10px;
    padding-left: 10px;
}

/* Outline　開催概要 */
#outline {
    padding: 60px 0;
    background: url('./assets/images/Seminarimages/outline-bg.jpg') no-repeat center;
    height: 120vh;
    width: 100%;
    overflow-x: hidden; /* 横スクロールを防止する */
}

#outline h2 {
    color: #006142;
}
#outline h2 .sec-title-en {
    color: #006142;
}

#outline > div {
    padding: 60px;
    background: rgba(255, 255, 255, 0.8);
    max-width: 752px;
    width: calc(90% - 120px);
    margin: 20px auto;
    border-radius: 15px;
    /* opacity: 0.01; */
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}
#outline > div > p {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
}
#outline table {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    border-spacing: 20px;
    font-size: 20px;
}
#outline table th {
    text-align: left;
    vertical-align: top;
    width: 20%;
}

/******* Q&A *******/
#faq {
    padding-top: 60px;
    padding-bottom: 50px;
    /* background:linear-gradient(90deg,#e3f3f0, #006142); */
    background: url('./assets/images/Seminarimages/faq-bg.jpg') no-repeat center;
    background-size: cover;
    width: 100%;
    overflow-x: hidden; /* 横スクロールを防止する */
}
#faq h2 {
    color: #006142;
}
#faq .sec-title-en {
    color: #006142;
}
.accordion-area {
    list-style: none;
    width: 95%;
    max-width: 900px;
    margin: 50px auto 0 auto;
    padding: 0;
}
.accordion-area li{
    margin: 10px 0;
}
.accordion-area section {
border: 1px solid #ccc;
}
.question {
    position: relative;/*+マークの位置基準とするためrelative指定*/
    cursor: pointer;
    font-size:1rem;
    font-weight: normal;
    padding: 10px;
    transition: all .5s ease;
}
/*アイコンの＋と×*/
.question::before,
.question::after{
    position: absolute;
    content:'';
    width: 15px;
    height: 2px;
    background-color: #333;
    
}
.question::before{
    top:48%;
    right: 15px;
    transform: rotate(0deg);
}
.question::after{    
    top:48%;
    right: 15px;
    transform: rotate(90deg);

}
/*　closeというクラスがついたら形状変化　*/
.question.close::before{
    transform: rotate(45deg);
}
.question.close::after{
    transform: rotate(-45deg);
}
.answer {
    display: none;/*はじめは非表示*/
    background: #f3f3f3;
    margin:0 3% 3% 3%;
    padding: 3%;
}
.question span,
.answer span {
    font-size: 24px;
    margin-right: 10px;
    font-weight: 700;
    color: #006142;
}

/* CONTACT お問い合わせ */
#contact {
    text-align: center;
    background:url("./assets/images/Seminarimages/contact-bg.jpg") no-repeat center;
    background-size: cover;
    margin-bottom: 0;
    padding-top: 60px;
    padding-bottom: 50px;
    width: 100%;
    overflow-x: hidden; /* 横スクロールを防止する */
}

.contact-form {
    margin: 80px auto 0 auto;
    width: 100%;
    max-width: 700px;
}
.form-item {
    border-top: 1px solid #ddd;
    padding-top: 24px;
    padding-bottom: 24px;
    width: 100%;
    display: flex;
    align-items: center;
}
.form-item:nth-child(5) {
    border-bottom: 1px solid #ddd;
}
.form-item-label {
    width: 100%;
    max-width: 248px;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    color: #fff;
}
.form-item-label.isMsg {
    margin-top: 8px;
    margin-bottom: auto;
}
.form-item-label-required {
    border-radius: 6px;
    margin-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 48px;
    display: inline-block;
    text-align: center;
    background: #006142;
    color: #fff;
    font-size: 14px;
}
.form-item-input {
    border: 1px solid #ddd;
    border-radius: 6px;
    margin-left: 40px;
    padding-left: 10px;
    padding-right: 10px;
    height: 48px;
    flex: 1;
    width: 100%;
    max-width: 410px;
    background: #eaedf2;
    font-size: 18px;
}
.form-item-select {
    border: 1px solid #ddd;
    border-radius: 6px;
    margin-left: 40px;
    padding-left: 10px;
    padding-right: 10px;
    height: 48px;
    flex: 1;
    width: 100%;
    max-width: 410px;
    background: #eaedf2;
    font-size: 18px;
}
.form-item-file {
    margin-left: 40px;
    padding-left: 10px;
    padding-right: 10px;
    height: 48px;
    max-width: 410px;
    font-size: 18px;
}
.form-item-textarea {
    border: 1px solid #ddd;
    border-radius: 6px;
    margin-left: 40px;
    padding-left: 10px;
    padding-right: 10px;
    height: 216px;
    flex: 1;
    width: 100%;
    max-width: 410px;
    background: #eaedf2;
    font-size: 18px;
}
.form-btn {
    border-radius: 6px;
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 280px;
    display: block;
    background: #FF7F3E;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
}

/******* フッター *******/
footer {
    background-color: #282828;
    text-align: center;
    padding: 40px 80px 80px 80px;
}
#copyright {
    color: #ffffff;
    text-align: right;
    font-size: 16px;
    font-weight: 400;
}
footer a {
    color: #fff;
    margin: 0 20px;
}
footer > div {
    display: flex;
    justify-content: center;
}

/*====================================================================
 PC用のスタイル
=====================================================================*/
@media screen and (min-width: 768px) {
    /* PCの時だけ改行させるクラス */
    .pcbr {
        display: block;
    }
    /* SPの時だけ改行させるクラス */
    .spbr {
        display: none;
    }
    #first-view {
        margin-top: 0px;
        /* min-height: 80vh !important; */
    }
    #fv-message > h1 {
        white-space: nowrap;
        line-height: 56px;
    }
    #about-elements > div > p {
        font-size: 28px;
    }
} 

/*====================================================================
 中間サイズの微調整
=====================================================================*/
@media (min-width: 768px)  and (max-width: 1120px) {

    #about-image {
        max-width: 95%;
    }
    .case-item {
        max-width: 95%;
    }
    #system-image img {
        width: 95%;
    }
    /* フッター */
    footer {
        background-color: #282828;
        text-align: center;
        padding: 20px 0 5px 0;
        font-size: 15px;
        font-weight: 400;
    }
    #copyright {
        color: #ffffff;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        margin-top: 20px;
    }
    footer a {
        color: #fff;
        margin: 0 20px;
    }
    footer > div {
        display: flex;
        flex-direction: column-reverse;
    }
}

/*====================================================================
 スマートフォン用のスタイル
=====================================================================*/
@media screen and (max-width: 767px) {

    /* article {
        overflow-x: hidden;
    } */
    /* PCの時だけ改行させるクラス */
    .pcbr {
        display: none;
    }
    /* SPの時だけ改行するクラス */
    .spbr {
        display: block;
    }

    /* 横幅設定 */
    body {
        margin: 0 auto 0 auto;
        width: 100%;
        overflow-x: hidden; /* 横スクロールを防止する */
    }
    /* ヘッダー */
    header {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 200;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        width: 100%;
        box-shadow: 4px 4px 15px 2px #ccc;
        overflow-x: hidden; /* 横スクロールを防止する */
    }
    header {
        display: none;
    }
    
    h1 {
        font-size: 42px;
    }

    /* ロゴのレイアウト */
    #logo {
        margin-left: 10px;
    }
    #logo img {
        height: 30px;
    }

    /* 固定タブを最初は隠す */
    #fixed-tab {
        bottom: 0px;
        height: 80px;
        transition: bottom 0.3s ease;
    }
    #fixed-tab.show {
        bottom: 0; /* アニメーションで表示する位置 */
    }
    /* 画面下部固定のメニュー */
    #fixed-tab > div {
        width: 30%;
        font-size: 16px;
        margin: 5px;
        padding: 5px;
    }
    #fixed-tab img {
        display: none;
    }

    /* ファーストビュー */
    #first-view {
        /* position: relative;
        display: flex;
        justify-content: center; */
        flex-wrap: wrap;
        align-items: center;
        background: url("./assets/images/Seminarimages/fv-bg-sp.jpg") no-repeat right;
        background-size: cover;
        background-position: center;
        width: 100%;
        overflow-x: hidden; /* 横スクロールを防止する */
    }
    #fv-message {
        font-weight: 800;
        height: 50%;
        width: 100%;
        z-index: 11;
        text-align: center;
        margin-top: -50px;
        /* padding-top: 0; */
    }
    #fv-message > h1 {
        font-size: 32px;
        font-weight: 800;
        margin: 0 0 10px 0;
    }
    #fv-message .middle {
        font-size: 28px;
    }
    #fv-message .small {
        font-size: 24px;
        margin-bottom: 0;
    }
    #fv-message .big {
        font-size: 40px;
    }
    #fv-message .date {
        font-size: 28px;
    }
    #fv-message .logo {
        margin: 0 0 0 20px;
        font-size: 16px;
        font-weight: 700;
        align-items: center;
        justify-content: center;
    }
    #fv-message p {
        margin: 5px;
        font-size: 16px;
        font-weight: 700;
    }
    #fv-image {
        margin-top: -10px;
        width: 75%;
        height: 40%;
        overflow: hidden;
    }
    #fv-image > div {
        width: 35%;
        margin: 5px 15px;
    }
    #fv-image img {
        height: 100%;
    }
    #fv-image p {
        position: absolute;
    }
    .fv-btn {
        margin: 10px auto;
        width: 95%;
        height: 50px;
        max-width: 300px;
    }

    /* ABOUT どんなイベント */
    #about {
        padding-top: 20px;
        padding-bottom: 10px;
        width: 100%;
        overflow-x: hidden; /* 横スクロールを防止する */
    }
    #about > div {
        width: 95%;
        margin: 0 auto;
    }
    #about h2 {
        font-size: 24px;
    }

    #about-message p {
        margin-top: 20px;
        font-size: 28px;
    }
    #about-elements {
        flex-wrap: wrap;
    }
    #about-elements > div {
        width: 43%;
        margin: 8px;
    }
    #about-elements > div > p {
        font-size: 16px;
    }

    /* 指導医師 */
    #fixed-moving {
        display: block;/*display:flex;を解除*/
        width: 100%;
        overflow-x: hidden; /* 横スクロールを防止する */
    }
    #fixed-area {
        position:relative!important;/*position stickyを解除*/
        width:100%;/*横幅を100%にして1列に見せる*/
        height: 40vh;/*縦幅を100vh⇒40vh　※任意の高さに設定可能*/
    }
    #moving-area {
        width:100%;/*横幅を100%にして1列に見せる*/
        min-height:auto;/*縦を100vh⇒解除*/
    }
    .moving-area-inner dt p {
        font-size: 20px;
    }
    .moving-area-inner dd {
        margin-left: 0;
    }
    .moving-area-inner .course-title {
        font-size: 24px;
    }
    .moving-area-inner .date {
        font-size: 32px;
    }
    .moving-area-inner dd img {
        max-width: 240px;
    }
    .moving-area-inner .doctor-title {
        font-size: 18px;
    }
    /* Outline 開催概要 */
    #outline {
        padding-top: 20px;
        width: 100%;
        overflow-x: hidden; /* 横スクロールを防止する */
    }
    #outline > div {
        padding: 10px;
        width: calc(95% - 20px);
    }
    #outline table {
        font-size: 18px;
    }
    #outline table tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }
    #outline table th {
        width: 100%;
    }

    /* Contact お問い合わせ */
    #contact > div {
        position: relative;
        width: 95%;
        margin: 0 auto;
    }
    .contact-form {
        margin-top: 40px;
    }
    .form-item {
        padding: 16px 0px;
        flex-wrap: wrap;
        width: calc(100% - 28px);
    }
    .form-item-label {
        max-width: inherit;
        display: flex;
        align-items: center;
        font-size: 15px;
    }
    .form-item-label.isMsg {
        margin-top: 0;
    }
    .form-item-label-required {
        border-radius: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
        width: 32px;
        font-size: 10px;
    }
    .form-item-inputt {
        margin-left: 0;
        margin-top: 18px;
        height: 40px;
        flex: inherit;
        font-size: 15px;
    }
    .form-item-textarea {
        margin-top: 18px;
        margin-left: 0;
        height: 200px;
        flex: inherit;
        font-size: 15px;
    }
    .form-btn {
        margin-top: 24px;
        padding-top: 8px;
        padding-bottom: 8px;
        width: 160px;
        font-size: 16px;
    }
    .contact-btn {
        width: 90%;
        font-size: 20px;
        margin: 0 auto;
        height: 59px;
    }
    /* フッター */
    footer {
        background-color: #282828;
        text-align: center;
        padding: 20px 0 80px 0;
        font-size: 15px;
        font-weight: 400;
    }
    #copyright {
        color: #ffffff;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        margin-top: 20px;
    }
    footer a {
        color: #fff;
        margin: 0 20px;
    }
    footer > div {
        display: flex;
        flex-direction: column-reverse;
    }
}

/**********************************************************
印刷時のスタイル
***********************************************************/
@page {
	margin: 10mm;
  size: 210mm 297mm; 
}
@media print {
  body {
    -webkit-print-color-adjust: exact; /* 印刷時でも背景色や背景画像を表示 */
    print-color-adjust: exact;
    width: 1190px; /* 印刷時の全ページ幅を統一（px数値はお好みで） */
    zoom: 0.8; /* なるべく多くのブラウザで切れないようにするため */
    top:0 !IMPORTANT;
    left:0 !IMPORTANT;
    width:200mm !IMPORTANT;
    height:290mm !IMPORTANT;
  }
}

.custom-calendar .rmdp-wrapper {
  font-size: 1em; /* カレンダー全体のフォントサイズを1.5倍に調整 */
}

.custom-calendar .rmdp-container {
  width: auto; /* カレンダー全体の幅を自動調整 */
}

.custom-calendar .rmdp-day {
  height: 3em; /* 各日付タイルの高さを調整 */
}

.custom-calendar .rmdp-day span {
  font-size: 1em; /* 日付のフォントサイズを調整 */
}

.custom-calendar .rmdp-header-values {
  font-size: 1.5em; /* ナビゲーションラベルのフォントサイズを調整 */
}

.custom-calendar .rmdp-arrow {
  font-size: 1.5em; /* ナビゲーション矢印のフォントサイズを調整 */
}

.custom-calendar .rmdp-day.rmdp-selected, 
.custom-calendar .rmdp-day.rmdp-today {
  background: white; /* アクティブタイルの背景色を変更（オプション） */
}

.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-slide {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-item {
  min-width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: 50px;
}

.carousel-item picture {
  width: 100%;
  height: 100%;
}

.carousel-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

@media (min-width: 768px) {
  .carousel-item img {
    height: 500px !important;
    object-fit: contain;
  }
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  color: #fff;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  cursor: pointer;
  user-select: none;
  z-index: 10;
}

.carousel-control.prev {
  left: 0;
}

.carousel-control.next {
  right: 0;
}